* {
  box-sizing: border-box;
}


html {
  height: 100%;
  font-size: 87.5%;
  scroll-behavior: smooth;
}

body {
  font-family: $font-family;
  font-size: 14px;
  color: $fontColor;
  font-weight: 400;
  padding: 0;
  margin: 0;
  height: 100%;

}

.login-page {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: block;
  background-image: url('/assets/images/Image-fond-login.jpg');
  background-size: cover;
  background-attachment: fixed;
  top: 0;
}
#main-login {
  position: relative;
}

a,
a:visited {
  color: $linkColor;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

b,
strong {
  font-weight: 500;
}

:focus,
:focus-visible {
  box-shadow: 0 0 0 2px #0091599f !important;
}

th:focus,
th:focus-visible,
tr:focus,
tr:focus-visible {
  box-shadow: none !important;
}

figure {
  margin: 0;
  padding: 0;
}

.ratio-image {
  aspect-ratio: 4/3;
  object-fit: cover;
}

.img-container{/*une classe pour mettre image complete dans un cadre de dimension 4:3*/
  width:400px;//largeur du cadre
  height: 300px;//Hauteur du cadre
  aspect-ratio: 4/3; //rapport 4:3
  overflow: hidden;//cacher les débordement
  display: flex;//centrer
  justify-content: center;//centre horizentale
  align-items: center;//centrer vertical
  background-color: transparent;//fond transparent
  position: relative;
}
.img-container img{
  max-width: 100%; // limite la largeur à 100% du conteneur
  max-height: 100%; // limite la hauteur à 100% du conteneur
  object-fit: contain;//garder l'image entiére sans coupure
  background-color: transparent; // fond transparent pour l'image
}

.hidden-opacity {
  visibility: hidden;
}

.layout-wrapper {
  display: block;


  .demo & {
    margin-top: $demoHeight;
  }

  .layout-main {
    margin: 0;
    margin-left: calc(90px + $spacer-4);
    //position: relative;
    position: static;
    padding-bottom: $spacer-5;
    padding-top: $spacer-5;
    transition: $animate-fx1;
    width: calc(100% - $spacer-6 - 90px);

    &.have-table {
      margin-top: $spacer-5;
      padding: 0;
    }



    body.is-sticky & {
      top: 70px;
    }

    &.no-nav {
      width: 100%;
      margin-left: 0;
    }

    &.have-secondary-nav {

      @media screen and (min-width: ($responsive-lg+250)) {
        width: calc(100% - $spacer-6 - 330px);
      }

    }

    &.no-header {
      top: 0;
    }

    .sticky & {
      top: 60px;
    }

    .page {
      max-width: 1250px;
      padding: 0 0.5rem;

      &.is-narrow {
        max-width: 550px;
      }

    }
  }
}

h1 {
  font-family: $font-bnp;
  font-style: normal;
  font-weight: 300;
  font-size: 2rem;
  margin: 0;
}

h2 {
  font-family: $font-bnp;
  font-weight: 500;
}

h3,
h4 {
  font-family: $font-family;
  font-weight: 500;
}

p {
  margin: 0 0 $spacer-2 0;
  line-height: 1.4em;

  &:last-child {
    margin-bottom: 0;
  }
}

.shadow {
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
}

.alert {
  color: $alertColor;
}

.focus {
  color: $focusColor;
}

.grey003 {
  color: $grey003;
}

.grey006 {
  color: $grey006;
}

ul {
  li {
    margin: $spacer-2 0;
  }

}

.is-centered {
  margin: 0 auto;
}

.grid-card {
  margin-bottom: $spacer-4;
  padding: 0 0.5rem;

  >div {
    padding: 0;
    margin: 0;
  }

  .card {
    padding: $spacer-6;
    background-color: $grey006;
    height: 100%;
    margin: 0;
  }

}



.card-map {
  height: 100%;

  @media screen and (max-width: 992px) {
    height: 70vh !important;
    min-height: 350px;
  }
}

.card {
  padding: $spacer-6;
  background-color: $grey006;
  margin-bottom: $spacer-4;

  .login-page & {
    background-color: #fff;
    box-shadow: $shadow-20;
  }
}

.card-large {
  box-shadow: $shadow-20;
  padding: $spacer-6;
  margin: $spacer-6;

  header {
    font-family: $font-bnp;

    .entete {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding-bottom: $spacer-3;
      margin-bottom: $spacer-6;
      border-bottom: 2px solid $grey004;
      text-align: right;
      // text-transform: uppercase;
      font-size: 1.15rem;
      line-height: 1em;
      font-weight: 500;
      position: relative;

      .label {
        position: absolute;
        top: 100%;
        right: 0;
        background-color: #1C2D37;
        padding: 8px 12px 6px 40px;
        img {
          width: 85%;
        }

      }
    }

    .titre {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      font-size: 2.25rem;
      margin-bottom: $spacer-6;
    }
    .id-dossier {
      color: $primaryColor;
      font-size: 1.4rem;
      font-weight: 500;
    }

  }

  section {
    margin-bottom: $spacer-4;
  }

  h2 {
    margin-bottom: $spacer-3;
    color: $primaryColor;
    font-weight: 400;
  }

  h1 {
    color: $primaryColor;
    font-size: 2.4rem;
    font-weight: 500;
    border-bottom: 3px solid $primaryColor;
    margin-bottom: $spacer-4;
    font-variant:small-caps;
  }

  .synthese {
  border-left: 3px solid $primaryColor;
  padding-left: $spacer-3;

    h1 {
      border: none;
      font-variant: normal;
    }
  }

}



form .col button,
form .col-fixed button {
  padding: 0.8rem 1.6rem;
}

fieldset {
  margin: 0;
  margin-inline: inherit;
  padding-inline: inherit;
  border: none;
  padding: 0;
}


.p-button.p-button-sm {
  padding: 0.89rem 2rem;
}

table .p-button.p-button-sm {
  padding: 6px 14px;

}

.p-message {
  display: block;
}

.p-dialog-header {
  .p-dialog-title {
    font-family: $font-bnp;
  }
}


.p-dynamic-dialog {
  .p-dialog-content {
    .p-dialog-footer {
      padding: 1rem 0rem 2rem 0rem;
    }
  }
}

.p-dialog-content {
  .p-dialog-footer {
    padding: 1rem 0rem 2rem 0rem;
  }
}

.p-dynamic-dialog-scrollable .p-dialog-mask {
  overflow: auto !important;

  .p-dialog-content {
    overflow: visible !important;
  }
}


@media screen and (max-width: 940px) {
  .p-dialog.p-dynamic-dialog {
    width: 100vw !important;
  }
}

.p-tooltip {
  max-width: 30rem;
}


.grid.gallery{
  figure {
    padding: 0;
    margin: 0;
    img {
      width: 100%;
      aspect-ratio: 4/3;
      object-fit: cover;
    }
    figcaption {
      display: flex;
      p {
        padding: 5px 0;
        flex-grow: 1;
        flex-basis: 0;
        display: block;
        margin: auto 0;
        font-size: 0.9em;
        line-height: 1.2em;
        color: $grey003;
      }
      .cta {

      }
      button {
        margin: 0;
        padding: 2px;
        width: auto;
      }
    }
  }


}
.upload-cta {
  background-color: $grey005;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $spacer-3;
  aspect-ratio: 4/3;
  width: 100%;
  > * {
    text-align: center;
   margin:$spacer-1 auto;
  }

  button {
    transform: scale(0.8);
    margin: 0 auto !important;
  }

}
