header.is-primary {
    margin-left: 90px;
    min-height: 60px;
    border-bottom: 3px solid $grey006;
    padding: $spacer-2 $spacer-4;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #fff;
    // width: calc( 100% - (90px + $spacer-4) );
    width: calc( 100% - 90px);
    z-index: 1000;
    top: 0;

    .content {
        display: flex;
        flex-direction: column;
    }

    .branding {
        display: flex;
        img {
            max-width: 200px;
            margin-right: $spacer-4;
        }
    }


    body.is-sticky & {
        top: 0;
        position: fixed;
        padding: $spacer-3 $spacer-2 $spacer-3 $spacer-4;
        min-height: inherit;
        border-bottom-color: #fff;
        height: 70px;
        &::after {
            height: 20px;
        }
        h1 {
            font-size: 1.5rem;
            margin: 0;
        }
        .sous-titre {
            font-size: 1rem;
            display: none;
        }
    }

    @media screen and (max-height:600px ) {
        min-height: 60px;
    }
 
    
    h1 {
        margin: 0;
        font-size: 1.8rem;

        span {
            color: $primaryColor;
        }
    }


    .sous-titre {
        font-size: 1.1rem;
        color: $grey003;

        &.progress {
            color: $alertColor;
        }
        .inc-icon {
            display: inline-block;
            margin-left: $spacer-1;
        }
    }

    .demo &{
      top: $demoHeight;
    }

}