// Tableau de correspondance dans le fichier icon-valea.pdf

.inc-icon {
  &::after {
    content: '';
    width: 28px;
    height: 28px;
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    font-family: 'valea_icons';
    font-size: 28px
  }

  &.is-small::after {
    width: 19px;
    height: 19px;
    font-size: 19px;
  }


  &.inc-icon-eye::after {
    content: 'W';
  }

  &.inc-icon-eye.is-small::after {
    content: 'w';
  }

  &.inc-icon-trash::after {
    content: 'T';
  }

  &.inc-icon-trash.is-small::after {
    content: 't';
  }

  &.inc-icon-flag::after {
    content: 'F';
  }

  &.inc-icon-flag.is-small::after {
    content: 'f';
  }

  &.inc-icon-bag::after {
    content: 'V';
  }

  &.inc-icon-bag.is-small::after {
    content: 'v';
  }

  &.inc-icon-agenda::after {
    content: 'C';
  }

  &.inc-icon-agenda.is-small::after {
    content: 'c';
  }

  &.inc-icon-euro::after {
    content: '€';
  }

  &.inc-icon-euro.is-small::after {
    content: '*';
  }

  &.inc-icon-file::after {
    content: 'G';
  }

  &.inc-icon-file.is-small::after {
    content: 'g';
  }

  &.inc-icon-arrow::after {
    content: 'A';
  }

  &.inc-icon-arrow.is-small::after {
    content: 'a';
  }

  &.inc-icon-flash::after {
    content: 'Z';
  }

  &.inc-icon-flash.is-small::after {
    content: 'z';
  }

  &.inc-icon-send::after {
    content: 'Q';
  }

  &.inc-icon-send.is-small::after {
    content: 'q';
  }

  &.inc-icon-night::after {
    content: 'H';
  }

  &.inc-icon-night.is-small::after {
    content: 'h';
  }

  &.inc-icon-save::after {
    content: 'R';
  }

  &.inc-icon-save.is-small::after {
    content: 'r';
  }

  &.inc-icon-flash-fill::after {
    content: '&';
  }

  &.inc-icon-load::after {
    content: 'D';
  }

  &.inc-icon-load.is-small::after {
    content: 'd';
  }

  &.inc-icon-close::after {
    content: 'X';
  }

  &.inc-icon-close.is-small::after {
    content: 'x';
  }

  &.inc-icon-check::after {
    content: 'J';
  }

  &.inc-icon-check.is-small::after {
    content: 'j';
  }

  &.inc-icon-excel::after {
    content: 'Y';
  }

  &.inc-icon-excel.is-small::after {
    content: 'y';
  }

  &.inc-icon-download::after {
    content: 'U';
  }

  &.inc-icon-download.is-small::after {
    content: 'u';
  }

  &.inc-icon-add::after {
    content: 'M';
  }

  &.inc-icon-add.is-small::after {
    content: 'm';
  }

  &.inc-icon-bell::after {
    content: 'B';
  }

  &.inc-icon-bell.is-small::after {
    content: 'b';
  }

  &.inc-icon-search::after {
    content: 'S';
  }

  &.inc-icon-search.is-small::after {
    content: 's';
  }

  &.inc-icon-settings::after {
    content: 'P';
  }

  &.inc-icon-settings.is-small::after {
    content: 'p';
  }

  &.inc-icon-edit::after {
    content: 'E';
  }

  &.inc-icon-edit.is-small::after {
    content: 'e';
  }

  &.inc-icon-home::after {
    content: '1';
  }

  &.inc-icon-home.is-small::after {
    content: '0';
  }

  &.inc-icon-help::after {
    content: '6';
  }

  &.inc-icon-help.is-small::after {
    content: '3';
  }

}


.p-button.p-button-sm .pi.pi-file-excel,
.pi.pi-file-excel
 {
  font-size: 120%;
}
