/* Customizations to the designer theme should be defined here */


.p-button {
  border-radius: 0;

  &:disabled {
    background-color: $disabledColor;
    border-color: $disabledColor;
  }

}

h5 {
  font-family: 'bnpp_sans';
  font-weight: normal;
}

.p-message .p-message-summary {
  font-weight: 500;
}

.p-button.p-button-sm {
  padding: 9px 14px;
  margin-right: 0.5rem;
}


.p-dialog {

  // @media screen and (max-width: 768px) {
  //   width: 100vw !important;
  //   min-width:auto;
  // }

  .p-dialog-footer {
    text-align: right;
  }

}



.p-datepicker table th>span {
  font-weight: 500;
}
