// Surcharge temporaire du theme ici. Les styles modifiés ici seront mis à jour au fil du projet dans les fichers scss correspondant.
// Veuillez notifier en commentaires (quand c'est possible) le fichier css/scss de destination
@import '/src/sass/variables';

p-dropdown.ng-dirty.ng-invalid > .p-dropdown,
p-inputnumber.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext,
.p-inputtext.ng-dirty.ng-invalid  {
  border: 1px solid #dd021c;
}

.obligatoire:after {
  content: " *";
  color: $errorColor;
}
.obligatoire {
  white-space : nowrap;
}

p-dropdown.obligatoire:after {
  content: " *";
  color: $errorColor;
}

