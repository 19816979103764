.dash-card {
  background-color: $primaryColor;
  padding: $spacer-5;
  border-radius: $spacer-1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 300px;
  color: #fff;


  .titre {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;

    h2 {
      margin: 0;
      flex-grow: 1;
      font-family: $font-bnp;
      font-size: 2rem;
    }

  }

  .sous-titre {
    margin: 0.5rem 0 0 0;
  }

  .compteur {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    p {
      font-size: 6rem;
      font-family: $font-bnp;
      font-weight: 300;

    }

  }

  .link-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    button {
      width: 100%;
      text-align: left;
      padding: 0.6rem 1rem 0.6rem 0rem;
      border: none;
      background-color: transparent;
      cursor: pointer;
      position: relative;
      text-transform: uppercase;
      letter-spacing: -0.01px;
      color: #fff;

      &:hover {
        opacity: 0.5;

        &::after {

          right: -$spacer-2;

        }
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: 50%;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMSAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcuODc1IDQuNjg3NUwxNC40Mzc1IDExLjI1TDcuODc1IDE3LjgxMjUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
        width: 21px;
        height: 22px;
        background-repeat: no-repeat;
        transform: translateY(-50%);
        transition: $animate-fx0;


      }
    }


  }

  &.create {
    background-color: $grey002;
  }

  &.affectation {
    background-color: $affectationColor;
  }

  &.alert {
    background-color: $greenDarkColor;
  }

  &.attente {
    background-color: $attenteColor;
  }

  &.view {
    background-color: $primaryColor;
  }

  &.profil {
    background-color: $profilColor;
    color: #3d3726;
    button {
    color: #3d3726;
    }
  }
  &.adminTech {
    background-color: $AdminTechlColor;
    color: #3d3726;
    button {
      color: #3d3726;
    }
  }
}
