/// Border of an overlay container element such as dialog or overlaypanel
/// @group overlay
$overlayContentBorder: 0 none;

/// Background of an overlay container element such as dialog or overlaypanel
/// @group overlay
$overlayContentBg:$panelContentBg;

/// Box shadow of an overlay container element such as dialog or overlaypanel
/// @group overlay
$overlayContainerShadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);

/// Background of a dialog header
/// @group overlay
$dialogHeaderBg: #ffffff;

/// Border of a dialog header
/// @group overlay
// $dialogHeaderBorder: 1px solid #dee2e6;
$dialogHeaderBorder: none;

/// Text color of a dialog header
/// @group overlay
$dialogHeaderTextColor: $panelHeaderTextColor;

/// Font weight of a dialog header
/// @group overlay
$dialogHeaderFontWeight: normal;

/// Font size of a dialog header
/// @group overlay
$dialogHeaderFontSize: 1.8rem;

/// Padding of a dialog header
/// @group overlay
$dialogHeaderPadding: 3rem 3rem 1rem 3rem;

/// Padding of a dialog content
/// @group overlay
$dialogContentPadding: 1rem 3rem;

/// Border of a dialog footer
/// @group overlay
//$dialogFooterBorder: 1px solid #dee2e6;
$dialogFooterBorder: none;

/// Padding of a dialog footer
/// @group overlay
$dialogFooterPadding: 1rem 3rem 3rem 3rem;

/// Background of a tooltip
/// @group overlay
$tooltipBg: #4a4a4a;

/// Text color of a tooltip
/// @group overlay
$tooltipTextColor: #ffffff;

/// Padding of a tooltip
/// @group overlay
$tooltipPadding: 0.8rem 1.2rem;