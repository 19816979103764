.field-line {
  margin-bottom: $spacer-1;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0px 1000px $primaryColorLight inset;
  box-shadow: 0 0 0px 1000px $primaryColorLight inset;
  transition: background-color 5000s ease-in-out 0s;
  border-color: $primaryColorLightBorder;
}

.form-title {
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.33em;
  text-align: left;
  margin: 0 0 15px 0px;
}

.field-section {
  font-weight: 500;
  margin-bottom: $spacer-2;
  font-size: 1.1rem;
}

form h2 {
  font-size: 1.3rem;
  margin: 0.5em 0;
}

.pin-code.p-inputtext {
  font-size: 1.2rem;
  text-align: center;
  margin-right: 0.2rem;
  border-radius: 8px;
  box-shadow: 0 0 15px #00000011;
  padding: 1rem 0.6rem;
  max-width: 2.5rem;

  &:last-child {
    margin-right: 0;
  }
}

.card,
.card-large {

  .label {
    color: $grey003;
  }

}


.p-checkbox-group,
.p-radiobutton-group,
.p-dropdown-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;



  >* {
    margin-right: $spacer-4;
    margin-bottom: $spacer-2;
  }

  &.inline-group {
    flex-direction: row;
  }
}



label span {
  font-size: 0.9em;
  font-style: italic;
  color: $grey003;
}

.p-dropdown {
  //min-width: 250px;

  .w-full & {
    width: 100%;
  }
}

.rulePassword {
  margin: $spacer-2 0 $spacer-4 0;
  padding: 0;
  list-style: none;
  color: $errorColor;
}

.formgroup-inline .p-button.p-button-sm {
  padding: 0.89rem 2rem;
}

.read-only-form {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.8rem;

  .p-dropdown-label,
  .p-dropdown-trigger {
    display: none;
  }

  i {
    display: none;
  }

  .w-full {
    //width: auto !important;
  }

  label {
    color: $grey003;
    margin: 0;
    padding: 0;
    white-space: nowrap;

    &::after {
      content: ' :';
    }
  }

  input,
  .p-inputtext-sm .p-inputtext {
    margin-left: 0.5em;
    background-color: transparent;
    border: none;
    padding: 0;

    &.p-disabled,
    &.p-component:disabled {
      opacity: 1;
    }

  }
}


.grid-inc {
  display: grid;
}

.col-fixed {
  @media screen and (max-width: 992px) {
    width: 100%;
  }
}

.col-resultat {

  @media screen and (max-width: 992px) {
    width: 100%;
    border-bottom: 1px solid $grey005;
    padding-bottom: 18px;

    .data-result {
      padding: 0;

      .pointille {
        display: none;
      }

      .resultat {
        margin: 0;
        padding: 0;
      }
    }

  }

}

.data-result {
  display: flex;
  height: 100%;
  padding-top: 27px;


  .pointille {
    flex-grow: 1;
    position: relative;
    margin: auto;

    &::before {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      position: absolute;
      top: 50%;
      border-top: 1px dashed #ccc;

    }

  }

  .resultat {
    margin: auto;
    padding-left: 1rem;

    span {
      padding: 0.7rem 0 0.7rem 1.2rem;
      font-weight: 500;
    }

  }

}


.p-inplace .p-inplace-display {
  display: inline-block !important;
  border: 1px solid transparent;

  margin-left: 3px;
}

.p-inplace-content,
.input-ghost.field .p-inplace-content
 {

  display: flex;


  .p-inputtext {
    border: 1px solid transparent;
    padding: 0.3rem 0.6rem;
    box-shadow: 0 0 0 2px #0091599f !important;


    &::placeholder {
      color: $grey004;
      font-style: italic;
    }


    &:focus,
    &:focus-visible {
      background-color: #fff;
    }

  }
  .p-button.p-button-icon-only {
width: 2rem;
padding: 0;
background-color: transparent;
color: $primaryColor;
border: 0;
  }
}


.input-ghost,
.input-inline {

  &.field {
    margin-bottom: 0;
    margin-left: -1.33rem;
    padding: 3px 0 3px 1.33rem;
    position: relative;

    &>label {
      margin-bottom: 0;
    }

  .p-inputtext {
    border: none;
    padding: 0.3rem 0.6rem;
    margin-left: 3px;

    &::placeholder {
      color: $grey004;
      font-style: italic;
    }


    &:focus,
    &:focus-visible {
      background-color: #fff;
    }



  }


  .p-dropdown {
    border: none;
    border-bottom: 1px solid $primaryColor;
    border-radius: 0;

    .p-dropdown-trigger {
      color: $primaryColor;
      padding: 0.2em 0 0 0.3em;

      .pi {
        font-size: 0.8rem;
      }
    }

  }

  }

}


.locked {
  position: relative;
  margin-left: -1.33rem;
  padding: 3px 0 3px 1.33rem;


  &:hover::before {
    font-family: 'primeicons';
    content: '\e95f';
    font-size: 0.9em;
    margin-left: 2px;
    color: $grey003;
    display: block;
    position: absolute;
    left: 0rem;
    top: 50%;
    transform: translateY(-50%);
  }

}

.input-ghost {

  &.field {

    &:hover {
      .p-inputtext:not(:focus) {
        color: $primaryColor;
        &::placeholder {
          color: $primaryColor;
        }
      }

      &.disabled:hover {
        .p-inputtext:not(:focus) {
          color: $grey003;
        }
      }

      &>label {
        //color: $primaryColor;
      }

      &>label::before {
        font-family: "valea_icons";
        font-size: 1.2em;
        color: $primaryColor;
        content: 'e';
        display: block;
        position: absolute;
        left: 0rem;
        top: 50%;
        transform: translateY(-50%);
      }

      &.disabled>label::before {
        font-family: 'primeicons';
        content: '\e95f';
        font-size: 0.9em;
        margin-left: 2px;
        color: $grey003;
      }



    }

  }

}

.input-inline {

  &.field {

    margin: 0 6px;
    padding: 3px 0 3px 0;

    &:hover {

      .p-inputtext:not(:focus,.p-dropdown-label) {
        color: $primaryColor;
      box-shadow: 0 0 0 1px #0091599f !important;
      background-color: #fff !important;
      }

      .p-dropdown:not(.p-inputwrapper-focus) {
        box-shadow: 0 0 0 1px #0091599f !important;
      }

    }


    .p-inputtext {
      padding: 0.2rem 0.5rem;
      border-radius: 2px;
    }


    .p-inputtext:not(:focus)
    {
     color: $primaryColor;
   }

      .inputfield.p-inputtext:not(:focus),
      .p-inputnumber-input:not(:focus)
       {
        background-color: $grey006;
      }


      &.disabled:hover {
        .p-inputtext:not(:focus) {
          color: $grey003;
        }
      }

      &.disabled>label::before {
        font-family: 'primeicons';
        content: '\e95f';
        font-size: 0.9em;
        margin-left: 2px;
        color: $grey003;
      }

      .p-dropdown {
        border-bottom: 1px solid $primaryColor;
        padding-right: 5px;
     //background-color: $grey006;
     border-radius: 0;
        .p-dropdown-label {
          margin: 0;
        }

        .p-dropdown-trigger {
          display: inherit;
          color: $primaryColor;
        }


    }

  }

}


.is-alert {
  color: $errorColor;
}


.p-inputtext.pinputtextInvalid, .dropdownError ::ng-deep .p-dropdown {
  border-color: $errorColor;
  }

  .label-invalid {
  color: $errorColor;
  }

  .is-centered-img {
  text-align : center;
  }

  .p-button.p-disabled {
  color: #333333;//$infoButtonTextColor;
  background: #f2f2f2;//$infoButtonBg;
  border: 1px solid #f2f2f2;//$infoButtonBorder;
  }

  .p-button-inline-block {
  display:inline-block;
  }

  .ql-image {
  visibility: hidden;
  }

  label.obligatoire:after {
  content: " *";
  color: $errorColor;;
  }

  .editor-error div.p-editor-content {
  border-color : $errorColor !important;
  }

  .editor-error div.p-editor-container .p-editor-toolbar.ql-snow {
  border: 1px solid $errorColor;
  }


  p-dropdown.ng-dirty.ng-invalid > .p-dropdown,
p-inputnumber.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext,
.p-inputtext.ng-dirty.ng-invalid  {
  border: 1px solid #dd021c;
}

.obligatoire:after {
  content: " *";
  color: $errorColor;
}
.obligatoire {
  white-space : nowrap;
}

p-dropdown.obligatoire:after {
  content: " *";
  color: $errorColor;
}
