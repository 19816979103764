nav.is-primary {

  width: 90px;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;

  .demo & {
    top: $demoHeight;
    height: calc(100% - $demoHeight);

    >.p-element {
      height: calc(100% - $demoHeight);
    }

  }

  >.p-element {
    height: 100%;
  }

  .logo {
    text-align: center;
   // border-bottom: 3px solid $grey006;
    //min-height: 125px;

    img {
      width: 72px;
      margin: 15px auto 10px auto;
    }
  }



  //calcul mediaquery verticaux

  @for $i from 1 through 15 {

    $responsiveCalculate: ($i*90)+125+60+"px";

    &.have-#{$i} {

      @media screen and (max-height:$responsiveCalculate) {



        .p-tieredmenu>.p-element>ul>li.p-menuitem {

          margin: auto;
          height: 100%;


          >a.p-menuitem-link {
            height: 100%;

            .p-menuitem-icon {
              margin: 0 !important;

              &::after {
                width: 20px;
                height: 20px;
                font-size: 20px;
              }
            }


          }

        }
      }



    }



  }

  .p-tieredmenu {

    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    height: 100%;


    >.p-element>ul {
      // premier niveau de la navigation
      height: 100%;
      display: flex;
      flex-direction: column;
    }


    .p-element.is-spacer {
      flex-grow: 1;
      a {
        display: none;
      }
    }


    li.p-menuitem {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0;
      transition: $animate-fx1;


      a.p-menuitem-link {
        flex-direction: column;
        font-size: 0.85rem;
        height: 90px;
        width: 90px;
        padding: 0;
        align-self: center;
        transition: $animate-fx1;
        justify-content: center;


        &.p-menuitem-link-active,
        .p-menuitem-link-active {


          background-color: transparent;

          .p-menuitem-text {
            color: $alertColor;
            font-weight: 500;
          }

          .p-menuitem-icon {
            color: $alertColor;
          }
        }

        &:hover {


          background-color: transparent;

          .p-menuitem-text {
            color: $primaryColor;
          }

          .p-menuitem-icon {
            color: $primaryColor;
          }
        }

        span.p-menuitem-text {
          text-align: center;
        }

        span.p-menuitem-icon {
          margin: 0 0 $spacer-2 0;
        }




      }

      &.p-menuitem-active>.p-menuitem-link {
        background-color: transparent;

        .p-menuitem-text {
          color: $linkColor;
        }
      }


      // @mixin navColor($BkColor:$primaryColorMedium, $BkColorActive:transparent, $IconColor:#000) {
      //   &:hover {
      //     background-color: $BkColor;

      //     .p-menuitem-icon,
      //     .p-menuitem-text {
      //       color: $primaryColor;
      //     }
      //   }

      //   &.p-menuitem-link-active,
      //   .p-menuitem-link-active {
      //     background-color: $BkColorActive;

      //     .p-menuitem-icon,
      //     .p-menuitem-text {
      //       color: $IconColor;
      //     }
      //   }
      // }

      // &.is-orange a.p-menuitem-link {
      //   @include navColor($IconColor:$alertColor);
      // }

      // &.is-yellow a.p-menuitem-link {
      //   @include navColor($IconColor:$alertColor);
      // }

      // &.is-green-dark a.p-menuitem-link {
      //   @include navColor($IconColor:$alertColor);
      // }

      // &.is-green-light a.p-menuitem-link {
      //   @include navColor($IconColor:$alertColor);
      // }



    }

    ul.p-submenu-list {

      box-shadow: $shadow-20;
      padding: $spacer-4;

      li.p-menuitem {



        a.p-menuitem-link {

          padding: 0.75rem 1rem;
          width: auto;
          height: auto;
          min-width: 200px;

          flex-direction: row-reverse;
          justify-content: space-between;

          border-bottom: 2px solid $grey006;

          // &:hover {
          //   .p-menuitem-text {}

          // }

        }

        &:last-child a.p-menuitem-link {
          border-bottom: none;
        }

      }

    }

    .p-submenu-icon {
      display: none;
    }

  }


}


nav.is-secondary {
  width: 250px;
  top: 0;
  right: 0;
  z-index: 1000;
  background-color: #fff;
  border-left: 1px solid $grey005;
  height: 100%;
  align-items: flex-start;
  padding: 0 0 0 0;

  display: flex;
  flex-direction: column;
  transition: $animate-fx1;

  @media screen and (max-width: ($responsive-lg+330)) {
    background-color: #fffffff4;
  }


  #collapseButton {
    padding: $spacer-2 0 $spacer-2 $spacer-2;
    position: absolute;
    left: 0;
    transform: translateX(-100%);
    top: 80px;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid $grey005;
    border-right: transparent;
    transition: $animate-fx1;
    border-radius: $spacer-3 0 0 $spacer-3;

    i {
      color: $primaryColor;
    }

    &:hover {
      padding-right: $spacer-2;

      i {
        color: darken($primaryColor, 20%);
      }
    }
  }

  &.is-close {
    right: -250px;

    #collapseButton {
      padding: $spacer-2;

      &:hover {
        padding-right: $spacer-3;
      }

    }
  }


  .demo & {
    top: $demoHeight;
  }


  .menus-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    flex-grow: 1;
    width: 100%;
    overflow: auto;
  }

  .menu-anchor,
  .menu-actions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: $spacer-4;
    width: 100%;


    p-menu {
      width: 100%;
    }

    .p-menu {

      background-color: transparent;

      border: none;
      width: 100%;

      .p-element {

        &:focus {
          box-shadow: none !important;
        }
      }

      .p-menuitem-link {
        padding: $spacer-2;

        &:focus {
          box-shadow: none !important;
        }

        .p-menuitem-text {
          font-size: 0.9rem;
        }
      }

      .p-submenu-header {
        color: $primaryColor;
        font-size: 1.1rem;
        padding: 0 0 $spacer-2 0;
        font-weight: 500;
        background-color: transparent;
      }

    }

  }

  .menu-anchor {
    .p-menu {
      .p-menuitem-link {


        &:hover,
        &.is-active {
          // background-color: transparent;
          background-color: $primaryColorMedium;

          .p-menuitem-text {
            color: $primaryColor;
          }
        }

      }
    }
  }

  .menu-actions {
    .p-menu {

      .p-menuitem {

        .p-menuitem-link {
          flex-direction: row-reverse;
          justify-content: space-between;

          border-top: 1px solid $grey005;
          padding: $spacer-3 0 $spacer-3 $spacer-2;

          &:hover {
            background-color: transparent;

            .p-menuitem-text,
            .p-menuitem-icon {
              color: $primaryColor;
            }

          }
        }

        &.primary-link {

          .p-menuitem-link {
            .p-menuitem-text {
              color: $primaryColor;
            }

            &:hover {
              background-color: $primaryColor;

              .p-menuitem-text,
              .p-menuitem-icon {
                color: #fff;
              }
            }

          }
        }

      }

    }
  }

  .menu-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: $spacer-4;
    width: 100%;
  }

}


nav.nav-steps {

  $baseAnim: 250ms;
  $diamBullet: 16px;

  padding: $spacer-7 0 $spacer-3 0;


  .col {
    padding: 0 $diamBullet/2;
    margin-bottom: $spacer-4;

  }

  h3 {
    margin: $spacer-5 0 $spacer-1 0;
    font-size: 1rem;
    margin-left: $diamBullet/2;
  }

  p {
    font-size: 0.9rem;
    margin-left: $diamBullet/2;
  }

  .echeance {
    flex-grow: 0;

    .progress-line {
      background-color: transparent;

      &::before {
        display: none;
      }

      .bullet {
        border-color: $primaryColor;
      }

    }

    &.is-cancelled .progress-line .bullet {
      border-color: $errorColor;
    }
  }

  .progress-line {
    height: 4px;
    width: 100%;
    background-color: $grey005;
    position: relative;
    transition: all $baseAnim ease-out;
    margin-left: $diamBullet;

    .bullet {
      width: $diamBullet;
      height: $diamBullet;
      border-radius: 50%;
      border: 3px solid $grey005;
      position: absolute;
      top: 2px;
      left: -$diamBullet/2;
      transform: translateX(-50%) translateY(-50%);
      transition: all $baseAnim ease-out;
      animation: bullet ease-out 300ms;
      animation-fill-mode: forwards;
    }

  }

  .is-active .progress-line::before,
  .is-cancelled:not(.echeance) .progress-line::before {
    content: '';
    display: block;
    position: absolute;
    height: 4px;
    width: 0%;
    background-color: $primaryColor;
    left: 0;
    animation: steps ease-out 300ms;
    animation-fill-mode: forwards;
  }

  .is-current .progress-line::before {
    background-color: $alertColor;
  }

  .is-active .bullet {
    border-color: $primaryColor;
  }

  .is-current .bullet {
    border-color: $alertColor;
  }

  .is-cancelled .bullet {
    border-color: $errorColor;
  }

  .is-cancelled:not(.echeance) .progress-line::before {
    background-color: $errorColor;
  }

  .text {
    opacity: 0;
    color: $grey005;
    animation: text-step ease-out 300ms;
    animation-fill-mode: forwards;

  }

  .is-active .text,
  .is-current .text,
  .echeance .text {
    color: #000;
  }

  .is-cancelled .text {
    color: $errorColor;
  }

  @for $i from 1 through 10 {

    .col:nth-child(#{$i}) {

      .progress-line::before,
      .bullet,
      .text {
        animation-delay: calc($baseAnim * $i);
      }
    }
  }


  @keyframes steps {
    0% {
      width: 0%;
    }

    100% {
      width: 100%;
    }
  }

  @keyframes bullet {
    0% {
      width: 0;
      height: 0;
    }

    100% {
      width: $diamBullet;
      height: $diamBullet;
    }
  }

  @keyframes text-step {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }


}

.version {
  text-align: center;
  color: $grey003;
  margin: $spacer-4 0;
  font-size: 0.9rem;
}


// Sous-menus Actions

.sub-menu {



  &.p-menu {
    padding: $spacer-3;
    min-width: 14rem;
    max-width: 20rem;
    width: auto;

    &.p-menu-overlay {
      box-shadow: $shadow-20;
      border: 2px solid $primaryColor;
      border-radius: $spacer-2;
    }


    .p-menuitem-link {
      flex-direction: row-reverse;

      .p-menuitem-icon {
        margin: 0;
      }

      .p-menuitem-text {
        flex-grow: 1;
        margin-right: 1rem;
      }
    }

  }


}
