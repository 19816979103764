$demoHeight : 65px;

$fontColor:#333333;
$primaryColor: #00915a;
$greenDarkColor: #00665A;
$primaryColorLight : #f4fbf4;
$primaryColorMedium :#def2df;
$primaryColorLightBorder : #95d195;
$linkColor : $primaryColor;
$secondaryColor : #333333;
$errorColor: #f00a0a;
$alertColor: #FF7A00;
$focusColor: $primaryColor;
$profilColor : #FFC000;
$AdminTechlColor : #95d195;


$affectationColor: $alertColor;
$attenteColor:$alertColor;

$grey001 : #282828;
$grey002 : #4f4f4f;
$grey003 : #828282;
$grey004 : #a5a5a5;
$grey005 : #e0e0e0;
$grey006 : #F6F6F6;


// Les id des spacers sont en cohérence avec les spacers padding de primeFlex
// https://www.primefaces.org/primeflex/padding

$spacer-0: 0;
$spacer-1: 0.25rem;
$spacer-2: 0.5rem; // 7px
$spacer-3: 1rem; // 14px
$spacer-4: 1.5rem; // 21px
$spacer-5: 2rem; // 28px
$spacer-6: 3rem;
$spacer-7: 3.5rem;
$spacer-8: 5rem;

$font-family : 'Graphik', Arial, Helvetica, sans-serif;
$font-bnp : 'bnpp_sans','Graphik', Arial, Helvetica, sans-serif;

$animate-fx1 : all 250ms ease-in-out;
$animate-fx0 : all 100ms ease-in-out;

$shadow-20 : 0px 0px 20px rgba(0, 0, 0, 0.2);
$shadow-20-bottom : 0px 0px 20px rgba(0, 0, 0, 0.2);

$responsive-lg : 992px;
$responsiveV : 700px;
$responsiveV-xs : 570px;
