.p-datatable table {
  font-size: 0.9rem;
}

.p-datatable-thead>tr>th {
  font-size: 0.95rem;
}


.p-paginator.p-paginator-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
 }

.p-datatable-thead>tr>th,
.p-datatable-tbody>tr>td,
.p-datatable-tfoot>tr>td,
.p-datatable-scrollable .p-datatable-thead>tr>th,
.p-datatable-scrollable .p-datatable-tbody>tr>td,
.p-datatable-scrollable .p-datatable-tfoot>tr>td{

  &.with-check {
    width: 3rem;
    flex-basis: 0;
    flex-grow: 0;
  }

  @for $i from 1 through 20 {

    &.width-#{$i} {
      width: #{$i}rem;
     }
    &.max-width-#{$i} {
      max-width: #{$i}rem;
    }
    &.min-width-#{$i} {
      min-width: #{$i}rem;
    }
  }

  &.width-date {
   width: 8rem;
  }

  &.is-large {
    width: 15rem;
    flex-grow: 1;
  }
}

.p-datatable-scrollable .p-datatable-thead>tr>th {
  line-height: 1.2em;
}

.tableaux-documents {

    .p-datatable-table {
        border-spacing: 0;
        border-collapse: collapse;
    }

  .p-datatable .p-datatable-thead>tr>th {
    background: #ffffff;
    color: $grey003;
    font-size: 0.85rem;
    border: none;
    padding: 0.8rem 1rem;
    line-height: 1.2em;
  }


  .p-datatable .p-datatable-tbody > tr  {
    background-color: $grey006;
    border-bottom: 2px solid #fff;
    cursor: pointer;
    &:hover{
        background-color: darken($grey006, 5%);
    }
}

  .p-datatable .p-datatable-tbody > tr > td {
      padding: 0.5rem 1rem;
      border: none;
  }

}

.p-paginator-bottom {
  // position: fixed;
  // bottom: 0;
width: calc(100% - 90px);
}

.frozen-shadow {

  // box-shadow: 6px 0px 10px #00000029;
  &::after {
    content: '';
    width: 16px;
    height: 100%;
    display: block;
    background:linear-gradient(90deg, rgba(0,0,0,0.18) 0%, rgba(0,0,0,0) 100%);
    position: absolute;
    right: -16px;
  }
}

.p-datatable .en_conflit {
  color: $errorColor !important;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
vertical-align: baseline !important;
}

.p-datatable .en_conflit {
  color: #f00a0a !important;//$errorColor;
}

.p-datatable {
  .icon-statut-A_AFFECTER, .icon-statut-D_ATTENTE_V1, .icon-statut-A_FACTURER {
    background: #fff8e3;
    border: 1px solid #fff8e3;
    border-radius: 2rem;

    span {
      display: inline-block;
      color: #FF7A00;
    }
  }

  .icon-statut-D_ATTENTE_V1-etatpeProjet {
    background: #FF7A00;
    border: 1px solid #FF7A00;
    border-radius: 2rem;

    span {
      display: inline-block;
      color: #fff8e3;
    }
  }

  .icon-statut-ENCOURS, .icon-statut-FACTUREE, .icon-statut-F_ENCOURS {
    background: #e1fce7;
    border: 1px solid #e1fce7;
    border-radius: 2rem;

    span {
      display: inline-block;
      color: #00915a;
    }
  }

  .icon-statut-ANNULE{
    color: #333333;
    background: #f2f2f2;
    border: 1px solid #f2f2f2;
    border-radius: 2rem;

    span {
      display: inline-block;
    }
  }
}


.thumbnail img{
  width: 100px;
  aspect-ratio: 4/3;
  object-fit: cover;
}
.description {
  font-size: 1.1em;
  a {
    font-weight:600;
  }
}
