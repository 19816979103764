/* You can add global styles to this file, and also import other style files */

@import '/src/sass/variables';
@import '/src/sass/global';
@import '/src/sass/navigation';
@import '/src/sass/header';
@import '/src/sass/icon';
@import '/src/sass/form';
@import '/src/sass/datatable';
@import '/src/sass/dashboard';

@import '/src/sass/avis';

.demo {

  code {
    color: $alertColor;
  }
}
