@font-face {
    font-family: 'Graphik';
    src: local(''), 
 url('fonts/Graphik-Semibold.woff2') format('woff2'),
        url('fonts/Graphik-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik';
    src: local(''),
 url('fonts/Graphik-Medium.woff2') format('woff2'),
        url('fonts/Graphik-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik';
    src: local(''), 
 url('fonts/Graphik-SemiboldItalic.woff2') format('woff2'),
        url('fonts/Graphik-SemiboldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Graphik';
    src: local(''), 
 url('fonts/Graphik-Regular.woff2') format('woff2'),
        url('fonts/Graphik-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik';
    src: local(''), 
 url('fonts/Graphik-LightItalic.woff2') format('woff2'),
        url('fonts/Graphik-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Graphik';
    src: local(''), 
 url('fonts/Graphik-MediumItalic.woff2') format('woff2'),
        url('fonts/Graphik-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Graphik';
    src: local(''), 
 url('fonts/Graphik-RegularItalic.woff2') format('woff2'),
        url('fonts/Graphik-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Graphik';
    src: local(''), 
 url('fonts/Graphik-Light.woff2') format('woff2'),
        url('fonts/Graphik-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik';
    src: local(''),
 url('fonts/Graphik-Bold.woff2') format('woff2'),
        url('fonts/Graphik-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik';
    src: local('') ,
 url('fonts/Graphik-BoldItalic.woff2') format('woff2'),
        url('fonts/Graphik-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

// BNP

@font-face {
    font-family: 'bnpp_sans';
    src: local(''), 
 url('fonts/bnpp_sans-webfont.woff2') format('woff2'),
         url('fonts/bnpp_sans-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'bnpp_sans';
    src: local(''), 
 url('fonts/bnpp_sans_italic-webfont.woff2') format('woff2'),
         url('fonts/bnpp_sans_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;

}

@font-face {
    font-family: 'bnpp_sans';
    src: local(''), 
 url('fonts/bnpp_sans_bold_italic-webfont.woff2') format('woff2'),
         url('fonts/bnpp_sans_bold_italic-webfont.woff') format('woff');
    font-weight: 600;
    font-style: italic;

}

@font-face {
    font-family: 'bnpp_sans';
    src: local(''), 
 url('fonts/bnpp_sans_bold-webfont.woff2') format('woff2'),
         url('fonts/bnpp_sans_bold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;

}


@font-face {
    font-family: 'bnpp_sans';
    src: local(''), 
 url('fonts/bnpp_sans_light_italic-webfont.woff2') format('woff2'),
         url('fonts/bnpp_sans_light_italic-webfont.woff') format('woff');
    font-weight: 300;
    font-style: italic;

}



@font-face {
    font-family: 'bnpp_sans';
    src: local(''), 
 url('fonts/bnpp_sans_light-webfont.woff2') format('woff2'),
         url('fonts/bnpp_sans_light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;

}


@font-face {
    font-family: 'valea_icons';
    src: local(''), 
 url('fonts/valea-icons-mono-webfont.woff2') format('woff2'),
         url('fonts/valea-icons-mono-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}